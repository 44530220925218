import React from "react";
import { withTranslation } from 'react-i18next';

import { InputTypes as IT } from "../enums/enums";
import Input from "./input";

class Formulario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            venta: {
                nombre_cliente: "",
                fecha_venta: new Date().toLocaleDateString("en-CA"),
                motivo_salida: "Venta",
                producto: {
                    codigo_producto: "",
                    cantidad: "",
                    precio_unitario: "",
                }
            },
            areAnyInputsEmpty: true
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="formulario">
                <div>
                    <Input type={IT.TEXT} name="nombre_cliente" placeholder={t("NOMBRE_CLIENTE")} onChange={(i) => { this.onChangeVenta(i) }} />
                    <Input type={IT.DATE} name="fecha_venta" placeholder={t("FECHA_VENTA")} onChange={(i) => { this.onChangeVenta(i) }} defaultValue={new Date().toLocaleDateString("en-CA")} />
                </div>
                <div>
                    <Input type={IT.NUMBER} placeholder={t("CODIGO_PRODUCTO")} name="codigo_producto" onChange={(i) => { this.onChange(i) }} />
                    <Input type={IT.NUMBER} placeholder={t("CANTIDAD")} name="cantidad" onChange={(i) => { this.onChange(i) }} />
                    <Input type={IT.NUMBER} placeholder={t("PRECIO_UNITARIO")} name="precio_unitario" onChange={(i) => { this.onChange(i) }} />
                    <div className="input-container">
                        <p>{t("MOTIVO_SALIDA")}</p>
                        <select className="input" name="motivo_salida" onChange={(i) => { this.onChange(i) }}>
                            {
                                [
                                    "Venta",
                                    "Venta Apoyo Socio",
                                    "Regalo",
                                    "Promoción",
                                    "Producto de muestra",
                                    "Uso casa",
                                    "Vencimiento",
                                    "Trueque",
                                    "Merma"
                                ].map((motivo) => { return <option value={motivo} key={motivo}>{motivo}</option> })
                            }
                        </select>
                    </div>
                </div>
                <div>
                    <button
                        className="btn"
                        onClick={() => {
                            this.props.onAgregarProducto({ producto: this.state.venta.producto });
                            this.clearInputs();
                        }}
                        disabled={this.areInputsEmpty()}>
                        {t("AGREGAR_PRODUCTO")}
                    </button>
                </div>
            </div>
        )
    }

    onChange(event) {
        this.setState({
            venta: {
                ...this.state.venta,
                producto: {
                    ...this.state.venta.producto,
                    [event.target.name]: event.target.value
                }
            },
            areAnyInputsEmpty: this.areInputsEmpty()
        })
    }

    onChangeVenta(event) {
        let newState = { ...this.state };
        newState.venta[event.target.name] = event.target.value;
        newState.areAnyInputsEmpty = this.areInputsEmpty();
        this.setState(newState);
        this.props.onChange({
            venta: {
                nombre_cliente: this.state.venta.nombre_cliente,
                fecha_venta: this.state.venta.fecha_venta,
                motivo_salida: this.state.venta.motivo_salida
            },
        })
    }

    areInputsEmpty() {
        let vacio = ["0", ""]
        return (
            vacio.includes(this.state.venta.producto.codigo_producto) ||
            vacio.includes(this.state.venta.producto.cantidad) ||
            this.state.venta.producto.precio_unitario === ""
        );
    }

    clearInputs() {
        ["codigo_producto", "cantidad", "precio_unitario"].forEach((input) => {
            document.getElementsByName(input)[0].value = "";
        })
    }
}

export default withTranslation()(Formulario);