import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './componentes/main';
import Estadistica from './componentes/estadistica';
import './index.css';
// eslint-disable-next-line
import { i18n } from './i18n/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/estadistica" element={<Estadistica />} />
        </Routes>
    </BrowserRouter>
);