import { useTranslation } from "react-i18next"

export default function Product(props) {
    const { t } = useTranslation()
    return (
        <div className="product-container" id={props.id}>
            <div className="product-container-item">
                <div className="contenedor-producto-item">
                    <p>{t("CODIGO_PRODUCTO_2")}</p>
                    <p>{props.codigo_producto}</p>
                </div>
                <div className="contenedor-producto-item">
                    <p>{t("CANTIDAD")}</p>
                    <p>{props.cantidad}</p>
                </div>
                <div className="contenedor-producto-item">
                    <p>{t("PRECIO_UNITARIO")}</p>
                    <p>{props.precio_unitario}</p>
                </div>
                <div className="contenedor-producto-item">
                    <p>{t("PRECIO_TOTAL")}</p>
                    <p>{props.precio_unitario * props.cantidad}</p>
                </div>
            </div>
            {
                props.onClose &&
                <div>
                    <button onClick={() => { props.onClose(props.id) }}>🗑️</button>
                </div>
            }
        </div>
    )

}