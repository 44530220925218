import React from "react";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { withTranslation } from "react-i18next";

import Formulario from "./formulario";
import Contenedor from "./contenedor";
import { client } from "../api/client";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            venta: {
                fecha_venta: new Date().toLocaleDateString("en-CA"),
                nombre_cliente: "",
                motivo_salida: ""
            },
            productos: []
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <div className="app">
                <Toaster position="top-center" />
                <button onClick={() => { i18n.changeLanguage("es-ES") }}>es-ES</button>
                <button onClick={() => { i18n.changeLanguage("en-US") }}>en-US</button>
                <Link to="/estadistica">{t("ESTADISTICA")}</Link>
                <h1>Davi Belleza</h1>
                <p>{t("INGRESAR_DATOS")}</p>
                <Formulario onChange={(venta) => { this.onAgregarVenta(venta) }} onAgregarProducto={(p) => { this.onAgregarProducto(p) }} />
                <Contenedor productos={this.state.productos} onClose={(p) => { this.onProductClose(p) }} />
                <button className="enviar" disabled={this.isButtonDisabled()} onClick={() => this.onEnviarClick()}>{t("ENVIAR")}</button>
            </div>
        );
    }

    onAgregarVenta(data) {
        let newState = {
            ...this.state,
            venta: data.venta,
        }
        this.setState(newState);
    }

    onAgregarProducto(data) {
        this.setState({ productos: [...this.state.productos, data.producto] });
    }

    isButtonDisabled() {
        return this.state.venta.nombre_cliente === "" || this.state.productos.length === 0;
    }

    onProductClose(product) {
        let newProducts = this.deleteByIndex(this.state.productos, product.id - 1)
        this.setState({ productos: newProducts });
    }

    deleteByIndex(arr, index) {
        let newArr = [];
        arr.forEach((item, i) => {
            if (i !== index) {
                newArr.push(item);
            }
        });
        return newArr;
    }

    onEnviarClick() {
        // Make this bulk
        Promise.all(this.state.productos.forEach((producto, i) => {
            client.insertData(this.state.venta, producto)
                .then(
                    data => {
                        console.log(data)
                        toast.success("Venta registrada con exito.");
                    })
                .catch(
                    error => {
                        console.log(error)
                        toast.error("Error al registrar la venta.");
                    }
                )
        })).finally(() => {
            this.setState({ productos: [] });
        })
    }

}

export default withTranslation()(App);