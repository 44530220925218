import React from "react";

import Product from "./product";

/*
{this.props.productos.map((producto, i) => {
                    return <div key={i}>{producto.codigo}: {producto.precioUnitario}*{producto.cantidad} = {producto.precioUnitario * producto.cantidad}</div>
                })}*/
/*
{productos.map((productos, index) => {
                    return <div key={index} className="item">{productos.map((producto, i) => {
                        return <Product key={i} {...producto} onClose={(p) => { this.onClose(p) }} id={this.state.id + 1} />
                    }
                    )}</div>
                }
                )}*/

class Contenedor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            times: this.getIdealTimes(),
            id: 0
        }
        window.addEventListener("resize", () => {
            let times = this.getIdealTimes();
            this.setState({
                times: times,
            })
        });
    }

    render() {
        // TODO: Add product validation.


        return (
            <div className="contenedor">
                {this.getComponents()}
            </div >
        )
    }

    getComponents() {
        let productos = createDivEveryXItems(this.props.productos, this.state.times);
        let components = []
        let indexi = 0;
        productos.forEach((productos, index) => {
            components[indexi] = <div key={index} className="item">{productos.map((producto, i) => {
                indexi++;
                return <Product key={i} {...producto} onClose={(p) => { this.onClose(p) }} id={indexi} />
            })}</div>

        })
        return components
    }

    getIdealTimes() {
        if (window.innerWidth < 900) {
            return 1;
        } else if (window.innerWidth < 1300) {
            return 2;
        } else {
            return 3;
        }
    }

    onClose(producto) {
        let element = document.getElementById(producto);
        this.props.onClose(element);
    }
}

function createDivEveryXItems(productos, times) {
    let divs = [];
    for (let i = 0; i < productos.length; i += times) {
        divs.push(productos.slice(i, i + times));
    }
    return divs;
}

export default Contenedor;