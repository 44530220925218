import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { client } from "../api/client";
import Product from "./product";
import Loader from "./loader";

class Estadistica extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ventas: [],
            ventasFetched: false,
        }
    }

    render() {
        const { t } = this.props;

        if (!this.state.ventasFetched) {
            client.getData().then(data => { this.setState({ ventas: data, ventasFetched: true }) });
        }

        return (
            !this.state.ventasFetched ? <Loader style={{ display: "block", margin: "auto" }} /> :
                <div>
                    <Link to="/">{t("VOLVER")}</Link>
                    <h1 style={{ textAlign: "center" }}>{t("ESTADISTICA")}</h1>
                    {
                        this.state.ventas.map((venta, i) => {
                            console.log(venta)
                            return <Product key={i} {...venta} />
                        })
                    }
                </div>
        );
    }
}

export default withTranslation()(Estadistica);