export default function Input(props) {
    return (
        <div className="input-container">
            <p>{props.placeholder}</p>
            <input
                type={props.type}
                placeholder={props.placeholder}
                className="input"
                name={props.name}
                onChange={(i) => { props.onChange(i) }}
                defaultValue={props.defaultValue}
                max={props.type === "date" ? new Date().toLocaleDateString("en-CA") : null}>
            </input>
        </div>
    )
}