import axios from "axios";

class DBClient {
    constructor() {
        // User and password for the database
        this.BASE_URL = "https://prueba.davi.cl/db"
    }

    getData() {
        const req = axios.get(this.BASE_URL + "/ventas.php")
        return req.then(res => res.data);
    }

    insertData(dataVenta, dataProducto) {
        const req = axios.post(this.BASE_URL + "/ventas.php", { ...dataVenta, ...dataProducto })
        return req.then(res => res.data);
    }
}

const client = new DBClient();

export { client };